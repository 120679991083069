@import 'variables';
@import 'mixins';
@import 'base';
@import 'typography';
@import 'markdown';
@import 'components/index';
@import 'routes/index';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}