@import 'styles/_variables.scss';

.slide-menu {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 200ms ease;
  z-index: 99;
  top: 120%;

  &.open {
    transform: translateY(-120%);
  }

  > div {
    padding: 0 10px;
    overflow-y: scroll;
    height: 100%;
  }
}
