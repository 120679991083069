body,
div,
img,
p,
ol,
em,
input,
small,
br,
strong,
code,
span,
a,
ul,
li,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  text-align: left;
  &::selection {
    background: $lightPink;
    color: white;
  }
  &::moz-selection {
    background: $lightPink;
    color: white;
  }
}

p,
a,
label,
input,
li,
i {
  @include responsiveFont($p);
  line-height: #{$p * 1.9};
}

.subrole li {
  @include responsiveFont($smallFont);
  a {
    @include responsiveFont($smallFont);
  }
}

small,
.small {
  @include responsiveFont($smallFont);
}

.xs {
  @include responsiveFont($tinyFont);
}

h5 {
  @include responsiveFont($h5);
}
h4 {
  @include responsiveFont($h4);
}

h3 {
  @include responsiveFont($h3);
}

h2 {
  @include responsiveFont($h2);
}

h1 {
  @include responsiveFont($h1);
}

p {
  font-family: Montserrat;
  font-weight: 300;
}

em {
  font-family: Montserrat;
}
ol,
small,
ul,
li,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
label,
a {
  font-family: Montserrat;
  font-weight: 400;
}

.h0 {
  font-family: Montserrat;
  font-weight: 900;
  text-transform: uppercase;
  @include responsiveFont($h1 * 1.4);
}

h1 {
  font-family: Montserrat;
  font-weight: 900;
}

h2 {
  font-weight: 600;
}

strong {
  font-family: Montserrat;
  font-weight: 400;
}

li {
  small {
    font-family: Montserrat;
    font-weight: 600;
  }
}

/* custom weight and style */

.bold {
  font-family: Montserrat;
  font-weight: 900;
}

.italic {
  font-family: Montserrat;
  font-style: italic;
}

/* links */

a {
  font-weight: bold;
  color: #1e3c89;
  &:hover {
    color: $lightPink;
    background: transparent;
    text-decoration: underline;
  }
}

.paragraph {
  max-width: 50rem;

  p {
    font-family: Montserrat;

    a {
      font-family: Montserrat;
      font-weight: 400;
    }
  }
}

.blue-links {
  a {
    strong {
      color: $electricBlue;
    }
  }
}

a.undecorated {
  text-decoration: none;
}

.undecorated {
  a {
    text-decoration: none;
  }
}
