.image-comp {
  transition: opacity 1s ease, height 500ms ease;
  height: auto;
}

.image {
  opacity: 0;
  color: transparent;
}

.image.image-loaded {
  opacity: 1;
}

@keyframes PULSE {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-loader-gradient {
  background: linear-gradient(270deg, lighten(#ccc, 20), darken(#ccc, 10));
  background-size: 150% 150%;
  color: transparent;
  width: 100%;
  display: block;

  animation-name: PULSE;
  animation-duration: 1.25s; /* or: Xms */
  animation-iteration-count: 8;
  animation-timing-function: ease;
}
