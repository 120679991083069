.header-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.9);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: $headerZ;

  .fa-bars {
    cursor: pointer;
    @include responsiveFont($h3);
  }

  .fa-bars:hover:before {
    color: $lightPink;
  }

  a {
    @include responsiveFont($h3);
  }
  .header-content {
    width: 100%;
  }

  .flex-link {
    display: flex;
    flex: 1;
  }

  .right-section {
    .fa {
      z-index: 1001;
      color: white;
      position: absolute;
      right: 0;
      @include responsiveFont($h3);
      line-height: #{$p * 1.7};
      margin-right: 0;
      transition: color 1s ease;

      &.open {
        color: black;
      }
    }
  }

  @media only screen and (min-device-width: 300px) {
    .header-content {
      display: flex;
      padding: 20px 0;
    }
  }

  .container.top-section {
    position: relative;
    top: 30%;
  }

  .pig-link {
    img {
      margin: (-15px) 15px -5px 0;
      vertical-align: middle;
      @media only screen and (min-device-width: 800px) {
        display: inline-block;
      }
    }
  }

  a {
    margin-right: 15px;

    &:link, &:visited {
      color: white;
    }

    &:hover {
      color: $lightPink;
      text-decoration: none;
    }

  }
}

.header-spacer {
  @media only screen and (min-device-width: 800px) {
    height: 100px;
  }
  @media only screen and (min-device-width: 300px) {
    height: 85px;
  }
}
