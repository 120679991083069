.panel-group {
  margin-bottom: 15px;
}

.panel,
.collapse-title {
  margin-bottom: 10px;
}

.panel {
  @include no-border;
  .panel-heading {
    @include no-border;
    padding: 0;
    .panel-title {
      .collapse-title {
      }
    }
  }
  .panel-collapse {
    @include no-border;
    .panel-body {
      padding-bottom: 0px;
      padding-top: 0px;
      @include no-border;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .role-subtitle {
    margin-bottom: 10px;
  }
}

.education-and-work-container {
  padding-bottom: 40px;
}

.resume {
  .main-resume-body {
    padding: 20px;
  }
  .main-resume-body.container {
    margin-bottom: 100px;

    @media (max-width: $small) {
      width: 100%;
      margin: 0;
    }
  }

  .header-area {
    margin-bottom: 50px;
    background-color: $lightGray;
    padding: 60px 0;

    @media (max-width: $small) {
      h2 {
        font-size: 1.5rem !important;
      }
    }
  }

  .header-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
  .header-text-section {
    flex: 8;
    justify-content: center;
    flex-direction: column;
    display: flex;
    @media (max-width: $medium) {
      font-size: 90% !important;
    }
  }
  .name-and-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .name {
    color: $electricBlue;
    margin: 0;
    font-family: Montserrat;
    font-weight: 900;
    font-size: 55px;
    display: inline;
    @media (max-width: $medium) {
      font-size: 45px;
    }
  }

  .link-icons {
    font-size: 110%;
    display: inline-block;
    position: relative;
    left: 15px;
    bottom: 3px;

    a {
      margin-right: 10px;
      color: black;
      cursor: pointer;
      &:hover {
        color: $lightPink;
      }
      i {
        margin-right: 0px;
        font-size: 120%;
        border-radius: 50%;
      }
    }
    img {
      margin: 0 4px 0 8px;
    }
    @media (max-width: $medium) {
      display: none;
    }
  }
  .self-description {
    color: $lightPink;
    margin: 0;
  }
  h1.section-header {
    color: $electricBlue;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  .collapse-title {
    margin-bottom: 0;
    @include mixed-link;
  }
  .item-title {
    margin-right: 0.5rem;
  }
  .panel,
  .collapse-title.top-level {
    margin-bottom: 20px;
  }
  .panel-collapse {
    margin-top: 20px;
  }

  ul {
    padding-left: 0;
  }

  li:hover.panel,
  li:hover.resume-link {
    &:before {
      color: darken($lightPink, 20);
    }
  }

  li.panel {
    > p {
      position: relative;
      top: 0;
      left: 30px;
      margin-top: 0;
      display: inline;
    }

    .paragraph {
      a {
        font-family: Montserrat;
      }
    }

    .roles > div.subsection {
      margin-left: 30px;
      p,
      a {
        @include responsiveFont($smallFont);
      }
    }

    .subrole {
      li {
        list-style-position: outside;
        margin-left: 20px;
        font-weight: 300;
      }

      li:not(:last-child) {
        margin-bottom: 5px;
      }

      li:last-child {
        margin-bottom: 15px;
      }

      li a {
        font-weight: 500;
      }

      li strong {
        font-weight: 500;
      }
    }

    .role-subtitle {
      text-decoration: underline;
      span.role-name {
        font-family: Montserrat;
        font-weight: 400;
      }
      .date {
        font-weight: 400;
      }
      small {
        strong {
          color: $electricBlue;
        }
      }
    }

    .roles {
      margin-top: 10px;
      .paragraph:first-child {
        p {
          margin-top: 0;
        }
      }
    }
    .roles,
    .fa {
      overflow: hidden;
      transition: all 0.7s ease;
    }
    .fa {
      transform: rotate(45deg);
    }
    &.closed {
      .roles {
        opacity: 0;
        max-height: 0;
        margin-top: 0;
      }
      .fa {
        transform: rotate(0deg);
      }
    }
  }

  li.panel:before {
    content: "\f055";
    font: normal normal normal 14px/1 FontAwesome;
    font-family: FontAwesome;
    color: $lightPink;
    font-size: 18px;
    margin-right: -15px;
    display: inline-block;
    transform: rotate(45deg);
    cursor: pointer;
    transition: all 0.5s ease;
  }

  li.panel.closed:before {
    transform: rotate(0deg);
  }

  li.resume-link:before {
    content: "\f061";
    font: normal normal normal 14px/1 FontAwesome;
    font-family: FontAwesome;
    color: $lightPink;
    font-size: 18px;
    padding-right: 15px;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  li.panel,
  li.resume-link {
    list-style-type: none;
    list-style-position: inside;
    position: relative;
  }

  .skills-overlay {
    background: linear-gradient(145deg, $lightPink, $lightPink 25%, $darkPink);

    .section-header {
      text-align: center;
      color: white;
      margin-top: 60px;
    }

    .skills {
      max-width: 60rem;
      margin: 20px auto 60px auto;
    }

    .container {
      height: 100%;
      overflow-y: scroll;
    }
  }

  .skill {
    font-family: Montserrat;
    font-weight: 900;
    color: white;
    line-height: 65px;
  }

  .skills-tab {
    color: $lightPink;

    i:hover {
      color: $darkPink;
    }
    i {
      transition: color 0.3s ease;
    }
    &.up {
      color: white;
    }
  }

  .skills {
    .skill {
      text-align: center;
      margin: 0 5px;
    }
  }

  .skills-tab {
    position: fixed;
    z-index: 100;
    left: 50%;
    margin-left: #{$h1 / 2}px;
    cursor: pointer;
    transition: all 0.7s ease;
    bottom: 2%;
    @media only screen and (max-width: $medium) {
      left: 45%;
    }
    i {
      @include responsiveFont($h1);
    }
    &.up {
      transform: rotate(180deg);
    }
  }

  .collapse-title {
    i.fa {
      margin-top: -1px;
    }
  }

  .headshot-box {
    flex: 2;
    margin-right: 5%;
    img {
      border-radius: 50%;
      width: 25vw;
      border-radius: 50%;
      max-width: 200px;
    }
  }
}
