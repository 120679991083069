.projects {
  margin-top: 50px;

  .loader {
    &:nth-of-type(even) {
      .portfolio-item {
        background-color: darken(white, 3);
      }
    }

    &:first-child .portfolio-item {
      padding-top: 0;
    }
  }

  .portfolio-item {
    padding: 70px 10px;

    &:nth-child(even) {
      background-color: $lightGray;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .port-header {
    h1 {
      margin-top: 20px;
    }
  }

  h1 {
    margin-bottom: 0px;
    &.for-mobile {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .markdown-container a > img:hover {
    border-color: $lightPink;
  }

  .portfolio-item a img {
    border: 5px solid black;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    background-color: gray;
    width: 100%;

    &:hover {
      border-color: $lightPink;
    }
  }

  .port-header ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .bullet-tag {
    list-style: none;
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    span {
      padding: 7px;
      background-color: darken($lightBlue, 20);
      transition: background-color 500ms ease;
      border-radius: 3px;
      cursor: pointer;
      color: white;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        background-color: darken($lightBlue, 30);
      }
    }
  }
}

.projects-menu {
  background: linear-gradient(
    145deg,
    black,
    lighten(black, 10) 60%,
    lighten(black, 20)
  );
  width: 100%;

  .portfolio-grid-loader {
    width: 90%;
    margin: 1% 0%;
    height: 350px;
    position: relative;
    display: inline-block;
    margin: 2% 0 2% 5%;

    @media only screen and (min-width: 800px) {
      width: 48%;
      margin: 1%;
      height: 300px;
    }

    @media only screen and (min-width: 1200px) {
      width: 30%;
      margin: 1.625%;
      height: 250px;
    }
  }

  .portfolio-grid__image-container {
    display: inline-block;
    background-color: black;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover {
      .portfolio-grid__image {
        opacity: 0.2;
      }
    }

    .portfolio-grid__image {
      opacity: 0.7;
      border: 2px solid white;
    }

    .portfolio-grid__title {
      opacity: 1;
    }
  }

  .portfolio-grid__title {
    transition: opacity 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    left: 50%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  .portfolio-grid__image {
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
