@mixin mixed-link-base {
  margin-bottom: 0;
  font-family: Montserrat;
  font-weight: 400;
  cursor: pointer;
  color: black;

  small {
    color: $electricBlue;
  }

  span.item-title {
    font-weight: 500;
  }

  span.icon {
    display: inline-block;
    width: 30px;
    i.fa {
      vertical-align: text-bottom;
      @media only screen and (min-device-width: 300px) {
        font-size: 1.8em;
      }

      @media only screen and (min-device-width: 800px) {
        font-size: 1.1em;
      }
    }
  }
  i {
    color: $lightPink;
    padding: 1px;
  }
}

@mixin mixed-link {
  @include mixed-link-base;
  .fa {
    color: $lightPink;
    font-size: 17px;
    vertical-align: text-top;
  }
  a {
    span {
      color: black;
      font-weight: 500;
      font-family: Montserrat;
    }
  }
  &:hover {
    color: darken($electricBlue, 22);
    small strong {
      color: darken($electricBlue, 10);
    }
    i {
      color: $darkPink;
    }
    a {
      color: $darkPink;
    }
  }
}

@mixin mixed-colored-link {
  @include mixed-link-base;
  color: $maroon;
  small {
    color: $electricBlue;
  }
  &:hover {
    color: $darkPink;
    small {
      color: $lightBlue;
    }
  }
}

@mixin no-border {
  background: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

@mixin responsiveFont($base) {
  @media only screen and (min-device-width: 800px) {
    font-size: #{$base}rem !important;
  }

  @media only screen and (min-device-width: 800px) {
    font-size: #{$base * $medMult}rem !important;
  }

  @media only screen and (min-device-width: 300px) {
    font-size: #{$base * $smallMult}rem !important;
  }

  &::selection {
    background: $lightPink;
    color: white;
  }
}
