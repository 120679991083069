.blog {
  margin-top: 2rem;
}

.blog, .blog-menu {
  h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  pre {
    font-family: monospace;
    text-align: left;

    &::selection {
      background-color: $lightPink;
      color: white;
    }

    &::moz-selection {
      background-color: $lightPink;
      color: white;
    }
  }

  /* code display */
  .codebox {
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 5px;
    padding-top: -20px;

    p {
      &::selection {
        background: $lightPink;
        color: white;
      }

      &::moz-selection {
        background: $lightPink;
        color: white;
      }
    }

    .highlight {

      pre {
        white-space: pre;
        overflow: scroll;
        word-wrap: normal;

        .o {
          margin-left: 0px;
        }

        .si {
          font-style: normal;
        }
      }
    }
  }

  .search {
    width: 175px;
    text-align: left;
  }

  input {
    padding: 6px 10px;
    margin-top: 100px;
    font-size: 35px !important;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    -webkit-appearance: none;
    outline: none;
    border-radius: 10px;
    text-align: left;
    max-width: 80%;

    &:focus {
      outline: none;
    }

    @media only screen and (max-device-width: 600px) {
      display: none;
    }
  }

  input::-webkit-input-placeholder {
    color: white !important;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: white !important;
  }

  input::-moz-placeholder {  /* Firefox 19+ */
    color: white !important;
  }

  input:-ms-input-placeholder {
    color: white !important;
  }

  ul {
    list-style: none;
    margin-top: 30px;
    margin-left: 0;
    padding: 0;
  }

  h2 {
    margin-bottom: 5px;

    .red {
      color: $maroon;
    }
  }
}

.blog-menu {
  background: linear-gradient(145deg, lighten($electricBlue, 20), darken($electricBlue, 10) 60%, darken($electricBlue, 25));
}

.blog-links {
  margin-top: 100px;

  .post-link {
    color: white;
    cursor: pointer;
    transition: color 400ms ease;

    small {
      color: darken($lightGray, 10);
      transition: color 400ms ease;
    }

    &:hover {
      color: $lightPink;

      small {
        color: $lightPink;
      }
    }
  }

}
