.markdown-container {
  width: 100%;

  .o {
    margin-left: 7px;
  }

  h6 {
    margin-bottom: -21px;
    width: 100%;
    background-color: #282c34;
    color: white;
    margin-top: 0;
    padding: 0.5em;
    padding-bottom: 0.5em;
    font-family: Inconsolata;
    font-weight: bold;
    &:hover {
      color: lighten($lightPink, 15);
    }
  }
  p,
  li {
    font-size: 16px;
    line-height: 1.5;
  }
  blockquote {
    margin: 20px 0;
    border-left: 4px solid #777777;
    padding: 5px 20px;
    background-color: #faf9f9;
    transition: background-color, border-left 0.5s ease;
    font-style: italic;

    &:hover {
      border-left: 4px solid $lightPink;
      background-color: lighten($lightPink, 40);
    }
  }
  li {
    list-style: initial;
    font-family: Montserrat;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  code {
    // background-color: transparent;
    font-weight: bold;
    font-family: monospace;
    // color: #333333;
  }

  .codebox {
    margin: 20px 0;
  }

  img {
    border: 5px solid black;
    margin: 20px 0;
    width: 100%;
    min-width: 100%;
    min-height: 50px;

    @media (max-width: $large) {
      &:hover {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  h2,
  h3 {
    margin-bottom: 10px;
    margin-top: 30px;
  }

  h4 {
    margin-top: 20px;
    margin-bottom: -15px;
    padding: 3px 0;
    z-index: 99;
    font-weight: bold;
  }

  p {
    code {
      font-family: Inconsolata;
      font-size: 19px;
      font-weight: 400;
    }
  }

  .code-box {
    border-radius: 5px;

    h6 {
      background-color: darken(#ccc, 50);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      transition: background-color 1000ms ease;

      &:hover {
        background-color: darken(#ccc, 40);
        color: white;
      }
    }

    pre {
      border-radius: 5px;
    }

    &.has-title pre {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  pre {
    border-top: none;
    font-family: Inconsolata;
    margin: 20px 0;
    white-space: pre;
    overflow: scroll;
    word-wrap: normal;
    font-size: 16px;

    code {
      font-family: Inconsolata;
      white-space: pre;
      overflow-x: scroll;
      word-wrap: normal;
      display: block;
      line-height: 1.5;
      span {
        font-size: 100%;
      }
    }
  }
  a:hover {
    color: $lightPink !important;
  }
  h6 {
    @include responsiveFont($p);
  }
}
