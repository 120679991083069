/* layout basics */

body {
  font-family: helvetica, sans-serif;
  margin: 0;
}

.core-layout {
  height: 100%;
}

.container {
  width: $large;
  display: block;
  margin: auto;

  @media (max-width: $large) {
    width: $medium;
  }

  @media (max-width: $medium) {
    width: $small;
  }
  @media (max-width: $small) {
    width: $mobile;
  }
  @media (max-width: $mobile) {
    width: 90%;
    margin: 0 5%;
  }
}

.inline {
  display: inline-block;
}

.inset {
  margin-left: 0.5em;
}

/* footer and header */
.logout {
  margin-left: -1.5em;
  margin-bottom: -2.5em;
}

/* prettified error messages */

#error_exp {
  color: #b94a48;
  font-family: calibri, verdana, "trebuchet ms";
  background-color: white;
}

#error_explanation {
  color: red;
  ul {
    list-style: none;
    margin: 0 0 18px 0;
    li {
      color: #a00000;
    }
  }
}

.alert {
  background-color: darken(#cccccc, 20);
  text-align: center;
  transition: all 1s ease;
  p {
    color: white;
    font-family: Montserrat;
    padding: 1.5em 0;
    margin: 0;
    text-align: center;
  }
  &.closed {
    height: 0;
  }
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

/* misc formatting & tricks */

.center {
  text-align: center;
  h1 {
    margin-bottom: 10px;
  }
}

.text-area {
  textarea {
    width: 90%;
    padding-right: 15px;
  }
}

.sidebar-nav-fixed {
  padding: 9px 0;
  position: fixed;
  margin-top: -10px;
  li, h4, h5, h6, .btn {
    padding-right: 15px;
  }
  h4, h5, h6, .btn {
    margin-right: 30px;
  }
}

.row-fluid > .span-fixed-sidebar {
  margin-left: 290px;
}

.centerify {
  text-align: center;
  p, ol, table, em, input, small, br, strong, pre, code, span, a, ul, li, td, th, h1, h2, h3, h4, h5, h6, label {
    text-align: center;
  }

  table {
    margin: 0;
  }
}

table.table thead tr th {
  font-weight: bold;
}

form.restful-item-form {
  width: 100%;
  .fields {
    input, textarea {
      width: 100%;
    }
    textarea {
      min-height: 100px;
    }
  }
}

.center_login {
  label, h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  form {
    .field, .actions {
      text-align: center;
      input {
        margin: auto;
      }
    }
  }
}

/* photo placement */

.padded {
  padding-left: 10px;
}

.border-it {
  border: solid 1px;
}

.bundle {
  padding: 2px;
  &:hover {
    color: $lightPink;
  }
  a:hover {
    color: $lightPink;
  }
}

.front-page-links {
  border: 1px solid;
  a {
    text-align: right;
  }
}

.row {
  a:hover {
    color: $lightPink;
  }
}

.no-side-margins {
  margin: 0 0 20px 0 !important;
}


.mobile-footer {
  margin-top: 0;
  line-height: 1.6;
  text-align: center;
  a {
    color: black;
    &:hover {
      color: $lightPink;
    }
  }
}

.mobile-only {
  display: none;
}

.big-screen {
  padding-top: 5px;
  padding-bottom: 5px;
  .only-show-big {
    p {
      margin-bottom: 0;
    }
  }
}

.mobile-markdown {
  padding-left: 10px;
  padding-right: 10px;
  h1, h2, h3, h4, h5, h6, pre {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  a {
  }
  p {
    margin-bottom: 25px;
  }
}

.mobile-navbar-spacer {
  height: 100px;
}

.mobile-header-links {
  .sixteen.border-bottom {
    border-bottom: 1px solid darken(gray, 20);
    a {
      display: block;
      width: 100%;
    }
  }
}

.light-gray-bg {
  background-color: $lightGray;
}

.bottom-sidebar {
  margin-top: 40px;
  .prevnext a {
  }
}

.skillz.mobile-only {
  width: 90%;
  .skill {
    .skill_links {
      font-weight: bold;
    }
    .stars {
      text-align: right;
    }
  }
}

.resume .mobile-only {
  .headshot_box {
    padding: 100px;
    width: 70%;
    margin-left: 15%;
    margin-top: -50px;
    margin-bottom: -80px;
  }
}

.portfolio-show {
  display: block;
}

.content-section {
  max-width: 700px;
  margin: auto;
  width: 100%;
}

.posts-show {
  display: flex;
  flex-direction: row;
  padding-bottom: 80px;

  .sidebar {
    padding-right: 3%;
    min-width: 30%;
    ul {
      list-style: none;
      padding-left: 0;
      line-height: 2;
    }
    @media only screen and (min-device-width: 300px) {
      width: 100%;
    }
    @media only screen and (min-device-width: 800px) {
      width: 30%;
    }
  }

  @media only screen and (max-width: $large) and (min-device-width: 300px) {
    flex-direction: column;
  }
  @media only screen and (min-width: $medium) and (min-device-width: 1024px) {
    flex-direction: row;
  }
}

input[type="text"], input[type="password"] {
  margin-bottom: 30px !important;
  font-size: #{$h2}rem !important;
  border: 2px solid black;
}

.mask {
  width: 100vw;
  height: 100vh;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;

  &.blank {
    background-color: rgba(255, 255, 255, 1);
  }
}
