@keyframes BOUNCE {
  0% {
    margin-bottom: 0px;
  }
  50% {
    margin-bottom: 6px;
  }
  100% {
    margin-bottom: 0px;
  }
}

.menu-tab {
  transform: translateX(-50%);
  position: fixed;
  z-index: 100;
  left: 50%;
  cursor: pointer;
  transition: all 200ms ease;
  bottom: 2%;
  padding: 10px 12px 15px 12px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  animation-name: BOUNCE;
  animation-duration: 0.25s; /* or: Xms */
  animation-iteration-count: 4;
  animation-timing-function: ease-in-out;
  animation-delay: 2s; /* or: Xms */

  i {
    @include responsiveFont($h1);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.up {
    transition: all 0.3s ease;
    bottom: 90%;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 15px 12px 10px 12px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }

    i {
      transform: rotate(180deg);
    }

    @media only screen and (max-device-width: 600px) {
      bottom: 85%;
    }
  }

  &.blog {
    color: $electricBlue;
  }

  &.resume {
    color: $lightPink;
  }

  &.projects {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
