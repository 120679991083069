// Heights
$headerHeight: 100px;
$headerZ: 65;
$skillsZ: 75;

// Dimensions
$large: 1000px;
$medium: 800px;
$small: 600px;
$mobile: 450px;

// Colors

$pink: #9d1515;
$lightPink: lighten($pink, 20);
$darkPink: darken($pink, 20);
$maroon: darken($pink, 30);

$electricBlue: #064c87;
$lightBlue: lighten($electricBlue, 15);
$darkBlue: darken($electricBlue, 15);

$lightGray: #eee;

$tinyFont: 0.6;
$smallFont: 0.8;
$p: 0.9;
$h5: 1.1;
$h4: 1.2;
$h3: 1.4;
$h2: 1.6;
$h1: 2;

$medMult: 1.1;
$smallMult: 1.2;
